import React, { useState } from "react"
import "./white-paper.scss"
import whitePaperEn from "../../static/white-paper/white-paper-img-en.png"
import whitePaperDe from "../../static/white-paper/white-paper-img-de.png"

import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import axios from "axios"
import { navigate } from "gatsby";

import {
  FormattedMessage,
  injectIntl,
  Link,
  useIntl,
} from "gatsby-plugin-react-intl"
import WhitePaperEnModal from "./terms-modals/terms-en"
import WhitePaperDeModal from "./terms-modals/terms-de"

const WhitePaper = () => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)

  const intl = useIntl()

  const whitePaperImg = intl.locale === "en" ? whitePaperEn : whitePaperDe;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })

  const onSubmit = async formValue => {
    setDisableSubmitBtn(true)
    axios
      .post(
        "https://vkujhbvizj.execute-api.eu-central-1.amazonaws.com/prod",
        formValue,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(response => {
        setDisableSubmitBtn(false)
        reset()
        const successPagePath =
          intl.locale === "en"
            ? "/en/whitepaper-success"
            : "/de/whitepaper-success";
        navigate(successPagePath);

        // onSubmitSuccess()
      })
      .catch(error => {
        setDisableSubmitBtn(false)
      })
  }

  const onSubmitSuccess = () => {
    setShowSuccessMsg(true)
    setTimeout(() => {
    }, 3000)
  }

  const onShowTermsModal = event => {
    event.preventDefault()
    event.stopPropagation()
    setShowTermsModal(true)
  }

  const getTermsModalAccordingToLng = () => {
    if (intl.locale === "en") {
      return (
        <WhitePaperEnModal
          show={showTermsModal}
          handleClose={() => setShowTermsModal(false)}
        />
      )
    } else
      return (
        <WhitePaperDeModal
          show={showTermsModal}
          handleClose={() => setShowTermsModal(false)}
        />
      )
  }

  return (
    <div className="white-paper">
      {showTermsModal && getTermsModalAccordingToLng()}
      <div className="white-paper-inner">
        <div className="white-paper-header">
          <h1>
            <FormattedMessage id="pages.whitePaper.title" />
          </h1>
          <p>
            <FormattedMessage id="pages.whitePaper.text" />
          </p>
        </div>

        <div className="white-paper-content">
          <div className="left">
            <img src={whitePaperImg} alt="White paper" />
          </div>
          <div style={{ paddingTop: "30px" }} className="right">
            <form
              style={{ width: "600px" }}
              className="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {!showSuccessMsg ? (
                <>
                  {" "}
                  <div className="form-header">
                    <FormattedMessage id="pages.whitePaper.form.title" />
                  </div>
                  {/* Email control */}
                  <div style={{ width: "100%" }} className="form-control">
                    <label htmlFor="email">
                      <FormattedMessage id="pages.whitePaper.form.controls.email.label" />
                    </label>
                    <input
                      {...register("email", {
                        required: true,
                      })}
                      name="email"
                      type="email"
                      id="email"
                      placeholder={intl.formatMessage({
                        id: "pages.whitePaper.form.controls.email.placeholder",
                      })}
                    ></input>

                    <div className="input-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 37 37"
                        fill="none"
                      >
                        <path
                          d="M37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37C28.7173 37 37 28.7173 37 18.5Z"
                          fill="#3B5EC9"
                        />
                        <path
                          d="M28 11.8875V15.4375L18 18.9875L8 15.4375V11.8875C8 11.6521 8.10536 11.4264 8.29289 11.2599C8.48043 11.0935 8.73478 11 9 11H27C27.2652 11 27.5196 11.0935 27.7071 11.2599C27.8946 11.4264 28 11.6521 28 11.8875ZM8 17.3492V24.3125C8 24.5479 8.10536 24.7736 8.29289 24.9401C8.48043 25.1065 8.73478 25.2 9 25.2H27C27.2652 25.2 27.5196 25.1065 27.7071 24.9401C27.8946 24.7736 28 24.5479 28 24.3125V17.3492L18 20.8992L8 17.3492Z"
                          fill="white"
                          stroke="white"
                        />
                      </svg>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={() => (
                        <span>
                          <FormattedMessage id="pages.whitePaper.form.controls.email.error" />
                        </span>
                      )}
                    />
                  </div>
                  {/* Accept control */}
                  <div
                    style={{ paddingBottom: "25px" }}
                    className="checkbox-control"
                  >
                    <div className="checkbox-inner">
                      <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        value="terms"
                        {...register("terms", {
                          required: true,
                        })}
                      ></input>
                      <label for="terms">
                        <span>
                          <FormattedMessage id="pages.whitePaper.form.controls.terms.left" />
                          &nbsp;
                        </span>
                        <span
                          onClick={e => onShowTermsModal(e)}
                          className="accept-terms-link"
                        >
                          <FormattedMessage id="pages.whitePaper.form.controls.terms.link" />
                        </span>
                        &nbsp;
                        <span>
                          <FormattedMessage id="pages.whitePaper.form.controls.terms.right" />
                        </span>
                      </label>
                    </div>

                    <ErrorMessage
                      errors={errors}
                      name="terms"
                      render={() => (
                        <span className="control-error">
                          <FormattedMessage id="pages.whitePaper.form.controls.terms.error" />
                        </span>
                      )}
                    />
                  </div>
                  <div className="submit-form">
                    <div className="send-form-button">
                      <button
                        disabled={disableSubmitBtn}
                        className="button-main"
                      >
                        <FormattedMessage id="pages.whitePaper.form.button" />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="form-success">
                  <div className="success-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM18.4158 9.70405C18.8055 9.31268 18.8041 8.67952 18.4127 8.28984L17.7041 7.58426C17.3127 7.19458 16.6796 7.19594 16.2899 7.58731L10.5183 13.3838L7.19723 10.1089C6.80398 9.72117 6.17083 9.7256 5.78305 10.1189L5.08092 10.8309C4.69314 11.2241 4.69758 11.8573 5.09083 12.2451L9.82912 16.9174C10.221 17.3039 10.8515 17.301 11.2399 16.911L18.4158 9.70405Z"
                          fill="#3B5EC9"
                        />{" "}
                      </g>
                    </svg>
                  </div>

                  <h2 className="success-title">
                    <FormattedMessage id="pages.whitePaper.form.confirmation.title" />
                  </h2>

                  <div className="success-content">
                    <p>
                      <span>
                        <FormattedMessage id="pages.whitePaper.form.confirmation.text" /> 
                      </span> <br/>
                      <span>
                        <b>
                          <FormattedMessage id="pages.whitePaper.form.confirmation.note" />
                        </b>{" "}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </form>

            <div className="benefits">
              <h2>
                <FormattedMessage id="pages.whitePaper.benefits.title" />
              </h2>
              <div className="benefit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM18.4158 9.70405C18.8055 9.31268 18.8041 8.67952 18.4127 8.28984L17.7041 7.58426C17.3127 7.19458 16.6796 7.19594 16.2899 7.58731L10.5183 13.3838L7.19723 10.1089C6.80398 9.72117 6.17083 9.7256 5.78305 10.1189L5.08092 10.8309C4.69314 11.2241 4.69758 11.8573 5.09083 12.2451L9.82912 16.9174C10.221 17.3039 10.8515 17.301 11.2399 16.911L18.4158 9.70405Z"
                      fill="#86d510"
                    />{" "}
                  </g>
                </svg>
                <FormattedMessage id="pages.whitePaper.benefits.item1" />
              </div>
              <div className="benefit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM18.4158 9.70405C18.8055 9.31268 18.8041 8.67952 18.4127 8.28984L17.7041 7.58426C17.3127 7.19458 16.6796 7.19594 16.2899 7.58731L10.5183 13.3838L7.19723 10.1089C6.80398 9.72117 6.17083 9.7256 5.78305 10.1189L5.08092 10.8309C4.69314 11.2241 4.69758 11.8573 5.09083 12.2451L9.82912 16.9174C10.221 17.3039 10.8515 17.301 11.2399 16.911L18.4158 9.70405Z"
                      fill="#86d510"
                    />{" "}
                  </g>
                </svg>
                <FormattedMessage id="pages.whitePaper.benefits.item2" />
              </div>
              <div className="benefit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM18.4158 9.70405C18.8055 9.31268 18.8041 8.67952 18.4127 8.28984L17.7041 7.58426C17.3127 7.19458 16.6796 7.19594 16.2899 7.58731L10.5183 13.3838L7.19723 10.1089C6.80398 9.72117 6.17083 9.7256 5.78305 10.1189L5.08092 10.8309C4.69314 11.2241 4.69758 11.8573 5.09083 12.2451L9.82912 16.9174C10.221 17.3039 10.8515 17.301 11.2399 16.911L18.4158 9.70405Z"
                      fill="#86d510"
                    />{" "}
                  </g>
                </svg>
                <FormattedMessage id="pages.whitePaper.benefits.item3" />
              </div>
            </div>
          </div>
        </div>

        <div className="white-paper-footer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1310 56"
            fill="none"
          >
            <path
              d="M0 56V0C0 46.1177 873.333 27.7255 1310 12.7647V56H0Z"
              fill="url(#paint0_linear_107_1199)"
            />
            <path
              d="M0 56V18.1482C0 49.3203 873.333 36.8886 1310 26.7762V56H0Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_107_1199"
                x1="0"
                y1="28"
                x2="1310"
                y2="28"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3B5EC9" />
                <stop offset="1" stop-color="#1D2E63" stop-opacity="0.75" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(WhitePaper)
