import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import WhitePaper from "../components/white-paper/white-paper"

 const WhitePaperPage = ({data, pageContext}) => {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KalBytes - White paper download</title>
        <link rel="canonical" href="https://www.kalbytes.de/" />
      </Helmet>

      <div>
        <WhitePaper></WhitePaper>
      </div>
    </Layout>
  )
}

export default WhitePaperPage;