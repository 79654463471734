// Modal.js
import React, { useEffect } from "react"

const WhitePaperEnModal = ({ show, handleClose }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none"

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [show])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  const handleClickOutside = event => {
    if (event.target.className === "modal display-block") {
      handleClose()
    }
  }

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="content">
          <h2>Terms and Conditions</h2>
          <p>
            By submitting your data, you confirm the download of the requested
            material and give your consent to receive email communications from
            Kalbytes GmbH. Your consent can be revoked at any time by
            sending an email to info@kalbytes.de.
          </p>
          <p>
            <b>Purpose of Collecting Your Email Address</b>
          </p>
          <p>
            By providing your email address, you agree to the following terms:
          </p>
          <p>
            <b>1. Whitepaper Distribution:</b>
            <br />
            Your email address will be used to send you the requested
            whitepaper.
          </p>
          <p>
            <b>2. Newsletters and Offers:</b>
            <br />
            Your email address will also be used to send you newsletters,
            promotional offers, and other marketing materials related to our
            products and services.
          </p>
          <p>
            <b>Legal Basis for Processing Your Data</b>
          </p>
          <p>
            Your consent is the legal basis for the processing of your email
            address for the purposes mentioned above.
          </p>
          <p>
            <b>Consent</b>
          </p>
          <p>
            By checking the consent box and submitting your email address, you
            acknowledge and agree that:
            <ul>
              <li>You have read and understood these terms.</li>
              <li>
                You consent to the use of your email address for the purposes
                specified above.
              </li>
              <li>You have read and understood these terms.</li>

              <li>
                You have the right to withdraw your consent at any time by
                clicking the “unsubscribe” link in our emails or by sending an
                email to info@kalbytes.de.
              </li>
            </ul>
            <br />
          </p>
        </div>
        <div className="footer">
          <button className="button-main" onClick={handleClose}>
            Close
          </button>
        </div>
      </section>
    </div>
  )
}

export default WhitePaperEnModal
