// Modal.js
import React, { useEffect } from 'react';

const WhitePaperDeModal = ({ show, handleClose }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });


  const handleClickOutside = (event) => {
    if (event.target.className === 'modal display-block') {
      document.body.style.overflow = 'auto';

      handleClose();
    }
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main" onClick={handleClickOutside}>
      <div className="content">
  <h2>Nutzungsbedingungen</h2>
  <p>
    Mit Absenden deiner Daten bestätigst du den Download des gewünschten Materials und gibst dein Einverständnis für das Zusenden von E-Mail Kommunikation durch Kalbytes GmbH. Das Einverständnis kann jederzeit per E-Mail an info@kalbytes.de widerrufen werden.
  </p>
  <p>
    <b>Zweck der Erfassung Ihrer E-Mail-Adresse</b>
  </p>
  <p>
    Durch die Angabe Ihrer E-Mail-Adresse stimmen Sie den folgenden Bedingungen zu:
  </p>
  <p>
    <b>1. Verteilung des Whitepapers:</b>
    <br />
    Ihre E-Mail-Adresse wird verwendet, um Ihnen das angeforderte Whitepaper zuzusenden.
  </p>
  <p>
    <b>2. Newsletter und Angebote:</b>
    <br />
    Ihre E-Mail-Adresse wird auch verwendet, um Ihnen Newsletter, Werbeangebote und andere Marketingmaterialien zu unseren Produkten und Dienstleistungen zuzusenden.
  </p>
  <p>
    <b>Rechtsgrundlage für die Verarbeitung Ihrer Daten</b>
  </p>
  <p>
    Ihre Einwilligung ist die Rechtsgrundlage für die Verarbeitung Ihrer E-Mail-Adresse zu den oben genannten Zwecken.
  </p>
  <p>
    <b>Einwilligung</b>
  </p>
  <p>
    Durch das Ankreuzen des Einwilligungskästchens und das Absenden Ihrer E-Mail-Adresse bestätigen und stimmen Sie zu, dass:
    <ul>
      <li>Sie diese Bedingungen gelesen und verstanden haben.</li>
      <li>Sie der Nutzung Ihrer E-Mail-Adresse für die oben angegebenen Zwecke zustimmen.</li>
      <li>Sie das Recht haben, Ihre Einwilligung jederzeit zu widerrufen, indem Sie auf den „Abmelden“-Link in unseren E-Mails klicken oder eine E-Mail an info@kalbytes.de senden.</li>
    </ul>
  </p>
</div>


        <div className='footer'>
          <button className='button-main' onClick={handleClose}>Schließen</button>
        </div>
      </section>
    </div>
  );
};

export default WhitePaperDeModal;
